<template>
  <div class="text">
    <div class="contain">
      <div class="title">
        坚定从教初心 矢志立德树人——顾晓园书记为2021年新教工做“入职第一讲”
      </div>
      <div class="info">
        <div class="info_left">
          <div>
            <span>2021-09-19</span>
            来源：<span>党委教师工作部</span> 浏览次数：<span>28</span>
          </div>
          <div>
            文字：<span>刘迪</span> 图片：<span>刘迪</span> 编辑：<span
              >刘迪</span
            >
          </div>
        </div>
        <div class="info_right">
          <div class="info_right_div1">
            <img src="../assets/img/share.png" class="shareImg" />
            <img src="../assets/img/wechat.png" class="wechatImg" />
          </div>
          <!-- <div class="info_right_div2">字号：[ 大 中 小 ]</div> -->
        </div>
      </div>
      <div class="lb">
        <div class="swiper-container gallery-top">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide swiper-top"
              v-for="(item, index) of bigImg"
              :key="index"
            >
              <img class="img" :src="item" />
              <div class="mask">
                <span class="swiper-num"
                  >{{ index + 1 }}/{{ bigImg.length }}</span
                >
                <span class="swiper-title"
                  >参与庆祝大会的二外师生在天安门广场</span
                >
              </div>
            </div>
          </div>
          <div class="swiper-button-next swiper-button-white">
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="swiper-button-prev swiper-button-white">
            <i class="el-icon-arrow-left"></i>
          </div>
        </div>
        <div style="height: 0.78125vw; width: 20.83333vw"></div>
        <div class="swiper-container gallery-thumbs">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide swiper-bottom"
              v-for="(item, index) of bigImg"
              :key="index"
            >
              <img class="img" :src="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div>上一篇：佳节梧桐下 青春正风华——欧洲学院中秋系列主题活动圆满举办</div>
      <div>下一篇：“国际传播与北京叙事”学术研讨会在京举行</div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      bigImg: [
        "https://t7.baidu.com/it/u=3165657288,4248157545&fm=193&f=GIF",
        "https://t7.baidu.com/it/u=2942499027,2479446682&fm=193&f=GIF",
        "https://t7.baidu.com/it/u=2610975262,3538281461&fm=193&f=GIF",
        "https://t7.baidu.com/it/u=4138158235,3956816634&fm=193&f=GIF",
      ],
    };
  },
  created() {
    this.$nextTick(function () {
      this.galleryThumbsLunbo();
      this.galleryTopLunbo();
    });
  },
  methods: {
    galleryTopLunbo() {
      this.galleryTop = new Swiper(".gallery-top", {
        spaceBetween: 0,
        loop: true,
        loopedSlides: 5,
        // 左右按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          //thumbs组件专门用于制作带缩略图的swiper
          swiper: this.galleryThumbs,
          slideThumbActiveClass: "swiper-slide-thumb-active",
        },
      });
    },
    galleryThumbsLunbo() {
      this.galleryThumbs = new Swiper(".gallery-thumbs", {
        spaceBetween: 15, //在slide之间设置距离（单位px）
        slidesPerView: 5, //设置slider容器能够同时显示的slides数量
        // loop: true, //设置为true 则开启loop模式
        freeMode: true, //默认为false，普通模式：slide滑动时只滑动一格
        loopedSlides: 7, //一般设置大于可视slide个数2个即可
        watchSlidesVisibility: true, //开启watchSlidesVisibility选项前需要先开启watchSlidesProgress
        watchSlidesProgress: true, //开启这个参数来计算每个slide的progress(进度、进程)
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  .contain {
    padding: 0 195px;
  }
  .title {
    font-size: 34px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 42px;
    text-align: center;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 43px;
    .info_left {
      height: 45px;
      font-size: 14px;
      font-family: NotoSansHans;
      color: #787878;
      line-height: 20px;
      padding-left: 17px;
      border-left: 1px solid #ccc;
      span {
        margin-right: 15px;
      }
    }
    .info_right {
      .info_right_div1 {
        display: flex;
        img {
          width: 42px;
          height: 42px;
        }
        .shareImg {
          margin-right: 18px;
        }
      }
      .info_right_div2 {
        height: 15px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-top: 10px;
      }
    }
  }
  .video {
    margin-top: 37px;
    margin-bottom: 50px;
    .myVideo {
      width: 100%;
      height: 532px;
    }
  }
  .nav {
    display: flex;
    height: 80px;
    border-top: 1px solid #eee;
    > div {
      flex: 1;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
      text-align: center;
      line-height: 80px;
      cursor: pointer;
    }
    > div:hover {
      color: #a12916;
    }
    > div:first-of-type {
      border-right: 1px solid #eee;
    }
  }
  .xgyd {
    margin-top: 50px;
  }
  .xgyd .fbt {
    color: #ababab;
    font-size: 24px;
    padding-left: 5px;
  }
  .xgyd .bt {
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
  }
  .xgyd .bt i {
    float: left;
    width: 4px;
    height: 30px;
    background: #ad2e1b;
    border-radius: 2px;
  }
  .xgyd .bt .name {
    font-size: 28px;
    color: #000;
    float: left;
    margin-left: 5px;
  }
  .lb {
    margin-top: 37px;
    margin-bottom: 50px;
    .img {
      width: 100%;
      height: 100%;
    }
    .gallery-top {
      width: 987px;
      height: 535px;
    }
    .gallery-thumbs {
      width: 987px;
      height: 102px;
      img {
        border: 1px solid #ccd2e7;
      }
    }
    .swiper-slide-thumb-active {
      border: 2px solid #284198;
    }
    .el-icon-arrow-left:before {
      content: "";
    }
    .el-icon-arrow-right:before {
      content: "";
    }
    .swiper-top {
      position: relative;
      .mask {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 160px;
        background: url("../assets/img/maskBg2.png");
        .swiper-title {
          position: absolute;
          left: 80px;
          bottom: 26px;
          height: 18px;
          font-size: 18px;
          font-family: NotoSansHans;
          color: #ffffff;
          line-height: 20px;
        }
        .swiper-num {
          position: absolute;
          left: 30px;
          bottom: 26px;
          height: 18px;
          font-size: 18px;
          font-family: NotoSansHans;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
