<template>
  <div class="text 22">
    <div class="contain">
      <div class="title">
        坚定从教初心 矢志立德树人——顾晓园书记为2021年新教工做“入职第一讲”
      </div>
      <div class="info">
        <div class="info_left">
          <div>
            <span>2021-09-19</span>
            来源：<span>党委教师工作部</span> 浏览次数：<span>28</span>
          </div>
          <div>
            文字：<span>刘迪</span> 图片：<span>刘迪</span> 编辑：<span
              >刘迪</span
            >
          </div>
        </div>
        <div class="info_right">
          <div class="info_right_div1">
            <img src="../assets/img/share.png" class="shareImg" />
            <img src="../assets/img/wechat.png" class="wechatImg" />
          </div>
          <!-- <div class="info_right_div2">字号：[ 大 中 小 ]</div> -->
        </div>
      </div>
      <div class="video">
        <video src="" class="myVideo"></video>
      </div>
      <!-- 相关视频 -->
      <div class="xgyd w1380">
        <p class="fbt">Related video</p>
        <p class="bt">
          <i></i>
          <span class="name">相关视频</span>
        </p>
        <ul class="list">
          <li class="list-item" v-for="(item, index) in 3" :key="index">
            <div class="videoImg">
              <img src="../assets/img/lb.png" />
              <img src="../assets/img/playCircle.png" />
            </div>
            <div class="video_tit">
              【2021教师节】新闻特写：送您一朵小红花，教师节快乐！
            </div>
            <div class="video_time">2021.09.18</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav">
      <div>上一篇：佳节梧桐下 青春正风华——欧洲学院中秋系列主题活动圆满举办</div>
      <div>下一篇：“国际传播与北京叙事”学术研讨会在京举行</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.text {
  .contain {
    padding: 0 195px;
  }
  .title {
    font-size: 34px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 42px;
    text-align: center;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 43px;
    .info_left {
      height: 45px;
      font-size: 14px;
      font-family: NotoSansHans;
      color: #787878;
      line-height: 20px;
      padding-left: 17px;
      border-left: 1px solid #ccc;
      span {
        margin-right: 15px;
      }
    }
    .info_right {
      .info_right_div1 {
        display: flex;
        img {
          width: 42px;
          height: 42px;
        }
        .shareImg {
          margin-right: 18px;
        }
      }
      .info_right_div2 {
        height: 15px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-top: 10px;
      }
    }
  }
  .video {
    margin-top: 37px;
    margin-bottom: 50px;
    .myVideo {
      width: 100%;
      height: 532px;
    }
  }
  .nav {
    display: flex;
    height: 80px;
    border-top: 1px solid #eee;
    > div {
      flex: 1;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
      text-align: center;
      line-height: 80px;
      cursor: pointer;
    }
    > div:hover {
      color: #a12916;
    }
    > div:first-of-type {
      border-right: 1px solid #eee;
    }
  }
  .xgyd {
    margin-top: 50px;
  }
  .xgyd .fbt {
    color: #ababab;
    font-size: 24px;
    padding-left: 5px;
  }
  .xgyd .bt {
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
  }
  .xgyd .bt i {
    float: left;
    width: 4px;
    height: 30px;
    background: #ad2e1b;
    border-radius: 2px;
  }
  .xgyd .bt .name {
    font-size: 28px;
    color: #000;
    float: left;
    margin-left: 5px;
  }
  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 55px;
    .list-item {
      width: 322px;
      .videoImg {
        position: relative;
        width: 322px;
        height: 182px;
        img:first-of-type {
          width: 100%;
          height: 100%;
        }
        img:last-of-type {
          position: absolute;
          bottom: 8px;
          left: 14px;
          width: 35px;
          height: 35px;
        }
      }
      .video_tit {
        width: 280px;
        height: 45px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        line-height: 28px;
        margin-top: 22px;
      }
      .video_time {
        font-size: 16px;
        font-family: PingFang SC;
        color: #000000;
        line-height: 28px;
        opacity: 0.8;
        margin-top: 15px;
      }
    }
  }
}
</style>
